import { Head, Layout, ListEvent } from "../components";

export default function Home() {
  return (
    <>
      <Head
        title="Home"
        bodyClass="hp"
        description="Studio fotografico, ma anche luogo di incontri, conferenze, eventi e workshop creati per apprendere nuove tecniche legate all'arte e al mondo dell'artigianato, ma anche salotto dal quale passare a fare quattro chiacchiere e scambiarsi idee.Spazio Supernova - via Borgazzi 87/A - 20900 Monza"
        url="/"
      />
      <Layout>
        {/* <section className={`flez-home list cards`}>
          <h2 style={{ color: "#000", fontSize: "2.6rem", width: "100%" }}>
            Molto presto altre cose belle, seguici!
          </h2>
        </section> */}
        <div className="hero">
          <h2 style={{ color: "#000", fontSize: "1.6rem", width: "100%" }}>
            Il 6 Aprile torna Super UAU Festival!!!
          </h2>
          {/* <div className="infos">
            <p>
              Hay amici illustratori a grande richiesta torna SuperUAU 🌟
              <br />
              Una giornata dedicata all'illustrazione e alle cose belle allo Spazio Supernova di
              Monza <strong>Domenica 6 Aprile dalle 10:00 alle 19:00</strong> UAU sarà ospite del
              meraviglioso Spazio Supernova con un super programma.
            </p>
            <p>
              Workshop per bambini, musica dal vivo, brunch per famiglie e un'area market
              selezionata completamente dedicata all'illustrazione.
            </p>
            <p>
              Vuoi esporre e vendere i tuoi capolavori? per candidarti raccontaci di te e del tuo
              stile scrivendoci una mail a{" "}
              <a href="mailto:info@spaziosupernova.it?subject=UAOILFESTIVAL-Supernova">
                spazio supernova
              </a>{" "}
              o{" "}
              <a href="mailto:uauilfestival@gmail.com?subject=UAOILFESTIVAL-Supernova">
                uau il festival
              </a>
              .
            </p>
            <p>
              A breve il programma completo dell'evento per guardare il mondo da nuovi punti di
              vista.
            </p>
          </div> */}
        </div>

        <section className={`flez-home list cards`}>
          <div className="row">
            {/* <h3>Eventi gratuiti</h3> */}
            {[
              {
                id: 10000,
                title: "Super UAU - 06/04/2025",
                link: "",
                category: { name: "events" },
                image_link: "20250406_slide_uau-3.png",
                image_link_large: "20250406_slide_uau-3.png",
              },
              {
                id: 10001,
                title: "Super UAU - 06/04/2025",
                link: "",
                category: { name: "events" },
                image_link: "20250406_slide_uau-4.png",
                image_link_large: "20250406_slide_uau-4.png",
              },
              {
                id: 10002,
                title: "Super UAU - 06/04/2025",
                link: "",
                category: { name: "events" },
                image_link: "20250406_slide_uau-5.png",
                image_link_large: "20250406_slide_uau-5.png",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {event.link ? (
                  <a href={event.link} title={event.title} target="_blank">
                    <picture className="full corner">
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                        media="(min-width:1000px)"
                        title={event.title}
                        src={`/assets/${event.category.name}/${event.image_link_large}`}
                        height={400}
                        width={1000}
                      />
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link}`}
                        media="(min-width:200px)"
                        src={`/assets/${event.category.name}/${event.image_link}`}
                        height={400}
                        width={360}
                      />
                      <img
                        alt={event.title}
                        src={`/assets/${event.category.name}/${event.image_link}`}
                      />
                    </picture>
                  </a>
                ) : (
                  <picture className="full corner">
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                      media="(min-width:1000px)"
                      title={event.title}
                      src={`/assets/${event.category.name}/${event.image_link_large}`}
                      height={400}
                      width={1000}
                    />
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link}`}
                      media="(min-width:200px)"
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      height={400}
                      width={360}
                    />
                    <img
                      alt={event.title}
                      src={`/assets/${event.category.name}/${event.image_link}`}
                    />
                  </picture>
                )}
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Eventi gratuiti</h3>
            {[
              {
                id: 10000,
                title: "Super UAU - 06/04/2025",
                link: "",
                category: { name: "events" },
                image_link: "20250406-live-uau-eventi-cibo.png",
                image_link_large: "20250406-live-uau-eventi-cibo.png",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {event.link ? (
                  <a href={event.link} title={event.title} target="_blank">
                    <picture className="full corner">
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                        media="(min-width:1000px)"
                        title={event.title}
                        src={`/assets/${event.category.name}/${event.image_link_large}`}
                        height={400}
                        width={1000}
                      />
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link}`}
                        media="(min-width:200px)"
                        src={`/assets/${event.category.name}/${event.image_link}`}
                        height={300}
                        width={300}
                      />
                      <img
                        alt={event.title}
                        src={`/assets/${event.category.name}/${event.image_link}`}
                      />
                    </picture>
                  </a>
                ) : (
                  <picture className="full corner">
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                      media="(min-width:1000px)"
                      title={event.title}
                      src={`/assets/${event.category.name}/${event.image_link_large}`}
                      height={400}
                      width={1000}
                    />
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link}`}
                      media="(min-width:200px)"
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      height={300}
                      width={300}
                    />
                    <img
                      alt={event.title}
                      src={`/assets/${event.category.name}/${event.image_link}`}
                    />
                  </picture>
                )}
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Prenota il tuo workshop</h3>
            {[
              {
                id: 10002,
                title: "Workshop - Timbra la luce - 06/04/2025",
                link: "https://spaziosupernova.bigcartel.com/product/timbra-la-luce",
                category: { name: "workshops" },
                image_link: "20250406-facciamo_le_laterne.png",
                image_link_large: "20250406-facciamo_le_laterne.png",
              },
              {
                id: 10003,
                title: "Workshop - Marionetta, string birds!- 06/04/2025",
                link: "https://spaziosupernova.bigcartel.com/product/marionetta-string-birds",
                category: { name: "workshops" },
                image_link: "20250406-uccelli-marionette.png",
                image_link_large: "20250406-uccelli-marionette.png",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {event.link ? (
                  <a href={event.link} title={event.title} target="_blank">
                    <picture className="full corner">
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                        media="(min-width:1000px)"
                        title={event.title}
                        src={`/assets/${event.category.name}/${event.image_link_large}`}
                        height={400}
                        width={1000}
                      />
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link}`}
                        media="(min-width:200px)"
                        src={`/assets/${event.category.name}/${event.image_link}`}
                        height={400}
                        width={360}
                      />
                      <img
                        alt={event.title}
                        src={`/assets/${event.category.name}/${event.image_link}`}
                      />
                    </picture>
                  </a>
                ) : (
                  <picture className="full corner">
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                      media="(min-width:1000px)"
                      title={event.title}
                      src={`/assets/${event.category.name}/${event.image_link_large}`}
                      height={400}
                      width={1000}
                    />
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link}`}
                      media="(min-width:200px)"
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      height={400}
                      width={360}
                    />
                    <img
                      alt={event.title}
                      src={`/assets/${event.category.name}/${event.image_link}`}
                    />
                  </picture>
                )}
              </article>
            ))}
          </div>
        </section>
        <div className="gang_starr">
          <div className="icon-star-b"></div>
          <div className="icon-star-b"></div>
          <div className="icon-star-b"></div>
        </div>
        <section className={`flez-home list cards`}>
          <h2 style={{ color: "#000", fontSize: "1.6rem", width: "100%" }}>
            I prossimi Workshop Serali
          </h2>
          <div className="col">
            {[
              {
                id: 10000,
                title: "SERATA FOOD EXPERIENCE - ORECCHIETTE CON LA CHEF GRAZIA BUA - 08/04/2025",
                link: "https://spaziosupernova.bigcartel.com/product/serata-food-experience",
                category: { name: "workshops" },
                image_link: "20250406_orecchiette.webp",
                image_link_large: "20250406_orecchiette.webp",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {event.link ? (
                  <a href={event.link} title={event.title} target="_blank">
                    <picture className="full corner">
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                        media="(min-width:1000px)"
                        title={event.title}
                        src={`/assets/${event.category.name}/${event.image_link_large}`}
                        height={400}
                        width={1000}
                      />
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link}`}
                        media="(min-width:200px)"
                        src={`/assets/${event.category.name}/${event.image_link}`}
                        height={300}
                        width={300}
                      />
                      <img
                        alt={event.title}
                        src={`/assets/${event.category.name}/${event.image_link}`}
                      />
                    </picture>
                  </a>
                ) : (
                  <picture className="full corner">
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                      media="(min-width:1000px)"
                      title={event.title}
                      src={`/assets/${event.category.name}/${event.image_link_large}`}
                      height={400}
                      width={1000}
                    />
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link}`}
                      media="(min-width:200px)"
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      height={300}
                      width={300}
                    />
                    <img
                      alt={event.title}
                      src={`/assets/${event.category.name}/${event.image_link}`}
                    />
                  </picture>
                )}
              </article>
            ))}
          </div>
          {/* <div className="col">
            <h3>Prenota il tuo workshop</h3>
            {[
              {
                id: 10001,
                title: "Supernova Market- 01/12/2024",
                link: "https://spaziosupernova.bigcartel.com/product/crea-la-tua-laterna",
                category: { name: "events" },
                image_link: "20241201_lanterna.png",
                image_link_large: "20241201_lanterna.png",
              },

              {
                id: 10002,
                title: "Supernova Market- 01/12/2024",
                link: "https://spaziosupernova.bigcartel.com/product/crea-il-tuo-astuccio-laboratorio-con-la-cricut",
                category: { name: "events" },
                image_link: "20241201_astuccio.png",
                image_link_large: "20241201_astuccio.png",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {event.link ? (
                  <a href={event.link} title={event.title} target="_blank">
                    <picture className="full corner">
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                        media="(min-width:1000px)"
                        title={event.title}
                        src={`/assets/${event.category.name}/${event.image_link_large}`}
                        height={400}
                        width={1000}
                      />
                      <source
                        srcSet={`/assets/${event.category.name}/${event.image_link}`}
                        media="(min-width:200px)"
                        src={`/assets/${event.category.name}/${event.image_link}`}
                        height={300}
                        width={300}
                      />
                      <img
                        alt={event.title}
                        src={`/assets/${event.category.name}/${event.image_link}`}
                      />
                    </picture>
                  </a>
                ) : (
                  <picture className="full corner">
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                      media="(min-width:1000px)"
                      title={event.title}
                      src={`/assets/${event.category.name}/${event.image_link_large}`}
                      height={400}
                      width={1000}
                    />
                    <source
                      srcSet={`/assets/${event.category.name}/${event.image_link}`}
                      media="(min-width:200px)"
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      height={300}
                      width={300}
                    />
                    <img
                      alt={event.title}
                      src={`/assets/${event.category.name}/${event.image_link}`}
                    />
                  </picture>
                )}
              </article>
            ))}
          </div>  */}
        </section>
        {/* <section className={`flez-home list cards`}>
          <h2>I prossimi workshop</h2>
          <div className="col">
            <h3>Workshop nei fine settimana</h3>
            {[
              {
                id: 1002,
                title: "CREA IL TUO ARCOBALENO! LAB DI MACRAMè - 08/03/2025",
                link: "https://spaziosupernova.bigcartel.com/product/crea-il-tuo-arcobaleno-lab-di-macrame",
                category: { name: "workshops" },
                image_link: "20250308_arcobaleno.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank" rel="noreferrer">
                  <figure className="full corner" style={{ maxHeight: "none" }}>
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      // width="500px"
                      // height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          */}
        {/* <div className="col">
            <h3>Workshop serali</h3>
            {[
              {
                id: 1101,
                title: "Corso di Maglia - V di Vipera - 03/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/corso-serale-di-maglia",
                category: { name: "workshops" },
                image_link: "20241003_maglia.png",
              },
              // {
              //   id: 1102,
              //   title: "Ricamo Creativo - Simona di La Beba Embroidery",
              //   link: "https://spaziosupernova.bigcartel.com/product/ricamo-creativo",
              //   category: { name: "workshops" },
              //   image_link: "20241108_ricamo_creativo.png",
              // },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank" rel="noreferrer">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Workshop bambini</h3>
            {[
              {
                id: 1203,
                title: "HALLOWEEN PIZZA PARTY - Piccolo Nido / Nido famiglia - 31/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/halloween-pizza-party",
                category: { name: "events" },
                image_link: "20241031_halloween.png",
              },
              {
                id: 1204,
                title: "Laboratorio Bimbi - Le Nanas di Niki / Art U Associazione - 03/11/2024",
                link: "https://spaziosupernova.bigcartel.com/product/laboratorio-per-bambini",
                category: { name: "workshops" },
                image_link: "20241103_le_nanas_lab.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div> 
        
        </section>*/}

        <section className="hero" style={{ marginBlock: "10rem" }}>
          <img src="assets/img/sayhi.jpg" alt="Spazio Supernova" style={{ maxHeight: "40rem" }} />
        </section>
        <ListEvent mode="next" />
      </Layout>
    </>
  );
}
